import { useState } from 'react';
import './data.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useRef } from 'react';
import Resume1 from './resume1';
import Resume2 from './resume2';
import Resume3 from './resume3';
import Resume4 from './resume4';
import Resume5 from './resume5';
import Resume6 from './resume6';
import Resume7 from './resume7';
import Resume8 from './resume8';
import Resume9 from './resume9';
import Resume10 from './resume10';
import Resume11 from './resume11';
import Resume12 from './resume12';
import Resume13 from './resume13';
import Resume14 from './resume14';
import Resume15 from './resume15';
import Resume16 from './resume16';
import Resume17 from './resume17';
import Resume18 from './resume18';
import Resume1d from './resume1d';
import Resume2d from './resume2d';
import Resume3d from './resume3d';
import Resume4d from './resume4d';
import Resume5d from './resume5d';
import Resume6d from './resume6d';
import Resume7d from './resume7d';
import Resume8d from './resume8d';
import Resume9d from './resume9d';
import Resume10d from './resume10d';
import Resume11d from './resume11d';
import Resume12d from './resume12d';
import Resume13d from './resume13d';
import Resume14d from './resume14d';
import Resume15d from './resume15d';
import Resume16d from './resume16d';
import Resume17d from './resume17d';
import Resume18d from './resume18d';
import { hasFormSubmit } from '@testing-library/user-event/dist/utils';

export default function Data(props) {

  const pdfRef = useRef();
  
   let download = ()=>{
      const input  = pdfRef.current;
      html2canvas(input).then((canvas)=> {
         const imgData = canvas.toDataURL('image/png');
         const pdf = new jsPDF('p','mm','a4', true);
         const pdfwidth = pdf.internal.pageSize.getWidth();
         const pdfHeight = pdf.internal.pageSize.getHeight();
         const imgWidth = canvas.width;
         const imgHeight = canvas.height;
         const ratio = Math.min(pdfwidth / imgWidth , pdfHeight/ imgHeight)
        const imgX = (pdfwidth - imgWidth* ratio) / 2;
        const imgY = 0;
        pdf.addImage(imgData, 'PNG' , imgX, imgY , imgWidth * ratio ,imgHeight * ratio);
        pdf.save('Resume');
      })
   } 

  let [value,setvalue]=useState({
        name:"",
        work:"",
        institute:"",
        course:"",
        additionalcourse:"",
        skills:"",
        experience:"",
        languages:"",
        phone:"",
        gmail:"",
        additionalaccount:"",
        projects:""

  });
 let [formcount,setformcount] = useState(0);
 let addform = ''
 switch(formcount){
  case 1: addform = 'active1'
  break;
  case 2: addform = 'active2'
  break;
  case 3: addform = 'active3'
  break;
  case 4: addform = 'active4'
  break;
  case 5: addform = 'active5'
  break;
  case 6: addform = 'active6'
  break;
 }

  let countplus=()=>{
  let countvalue1 = formcount;
  countvalue1 = countvalue1 + 1;
   setformcount(countvalue1);
  }
  let countminus=()=>{
    let countvalue2 = formcount;
    countvalue2 = countvalue2 - 1;
     setformcount(countvalue2);
    }
  let getdata =(event)=>{
  let data={...value}
  let  inpvalue= event.target.value;
  let  inpname= event.target.name;
   data[inpname] = inpvalue;
   setvalue(data);
}

let [finalres,setfinalres] = useState();
let [downloadres,setdownloadres] =useState();
let getresume = ()=>{

    if(value.name==""||value.work==""||value.institute==""||value.course==""||value.skills==""||value.experience==""||value.languages==""||value.phone==""||value.gmail==""||value.additionalaccount=="")
      {
        toast.error('Please fill out the complete');
      }
    else{
      setpages(1);
      switch(props.index){
        case 0: setfinalres(<Resume2 rdata ={value}/>)
                setdownloadres(<Resume2d rdata ={value}/>)
        break;
        case 1: setfinalres(<Resume10 rdata ={value}/>)
                setdownloadres(<Resume10d rdata ={value}/>)
        break;
        case 2: setfinalres(<Resume3 rdata={value}/>)
                setdownloadres(<Resume3d rdata ={value}/>)
        break;
        case 3: setfinalres(<Resume4 rdata={value}/>)
                setdownloadres(<Resume4d rdata ={value}/>)
        break;
        case 4: setfinalres(<Resume5 rdata={value}/>)
                setdownloadres(<Resume5d rdata ={value}/>)
        break;
        case 5: setfinalres(<Resume1 rdata={value}/>)
                setdownloadres(<Resume1d rdata ={value}/>)
        break;
        case 6: setfinalres(<Resume7 rdata={value}/>)
                setdownloadres(<Resume7d rdata ={value}/>)
        break;
        case 7: setfinalres(<Resume8 rdata={value}/>)
                setdownloadres(<Resume8d rdata ={value}/>)
        break;
        case 8: setfinalres(<Resume9 rdata={value}/>)
                setdownloadres(<Resume9d rdata ={value}/>)
        break;
        case 9: setfinalres(<Resume2 rdata={value}/>)
                setdownloadres(<Resume2d rdata ={value}/>)
        break;
        case 10: setfinalres(<Resume11 rdata={value}/>)
                 setdownloadres(<Resume11d rdata ={value}/>)
        break;
        case 11: setfinalres(<Resume12 rdata={value}/>)
                 setdownloadres(<Resume12d rdata ={value}/>)
        break;
        case 12: setfinalres(<Resume13 rdata={value}/>)
                 setdownloadres(<Resume13d rdata ={value}/>)
        break;
        case 13: setfinalres(<Resume14 rdata={value}/>)
                 setdownloadres(<Resume14d rdata ={value}/>)
        break;
        case 14: setfinalres(<Resume15 rdata={value}/>)
                 setdownloadres(<Resume15d rdata ={value}/>)
        break;
        case 15: setfinalres(<Resume16 rdata={value}/>)
                 setdownloadres(<Resume16d rdata ={value}/>)
        break;
        case 16: setfinalres(<Resume17 rdata={value}/>)
                 setdownloadres(<Resume17d rdata ={value}/>)
        break;
        case 17: setfinalres(<Resume18 rdata={value}/>)
                 setdownloadres(<Resume18d rdata ={value}/>)
        break;
        }
    }
}
let [pages,setpages]=useState(0);
if(props.hide==0 && pages==1)
{ 
  setpages(0);
  setformcount(0);
  setvalue({
        name:"",
        work:"",
        institute:"",
        course:"",
        additionalcourse:"",
        skills:"",
        experience:"",
        languages:"",
        phone:"",
        gmail:"",
        additionalaccount:"",
        projects:""
  });
}
    return(<> 
    <ToastContainer  pauseOnHover={false} />
    <div className={`parent-body ${ props.hide==1? "active-parent-body" : "" }`}>
          <div className={`main-body ${ pages==1? "active-main-body" : "" }`}>
          <div className='data-body'>
          <div className='design'></div>
          <div className='warning'>Please use our next button for next page</div>
          <div className='data'> 
           < div className={`form ${addform}`} onSubmit={getdata}>
           <div className="name">
           <div><h1>Name:</h1><input className='input-capital' type='text' onChange={getdata} name='name' value={value.name} placeholder='Your Name'/></div>
           <div>
            <h1>Work as a:</h1><input className='input-capital' type='text' onChange={getdata} name='work' value={value.work} placeholder='Web Developer, Interior Designer, Site Supervisor, Graphic Designer'/></div>
           </div>
           <div className="education">
           <div><h1>Institute/College Name:</h1><input className='input-capital' type='text' onChange={getdata} name='institute' value={value.institute} placeholder='XYZ Institute Of Technology (Location)'/></div>
           <div><h1>Course:</h1><input className='input-capital' type='text' onChange={getdata} name='course' value={value.course} placeholder='Bachelor of Technology (2020-2024)'/></div>
           <div><h1>Additional course: (Optional)</h1><input className='input-capital' type='text' onChange={getdata} name='additionalcourse' value={value.additionalcourse} placeholder='Diploma (2018-2020)'/></div>
           </div>
           <div className="skills">
           <div><h1>Skills:</h1><input className='input-capital' type='text' onChange={getdata} name='skills' value={value.skills} placeholder='Problem Solving, Good Communication Skills, Leadership Skills'/></div>
           <div className='suggetion'><h1>Suggetion-</h1><ul><li>Problem Solving</li><li>Good Communication Skills</li><li>Leadership Skills</li><li>Management Skills</li></ul></div>
           </div>
           <div className="experience">
           <div><h1>Experience:</h1><input className='input-capital' type='text' onChange={getdata} name='experience' value={value.experience}/></div>
           <div className='suggetion'><h1>Suggetion-</h1>
           <ul>
            <li>Fresher</li>
            <li>1 year as a Frontend Engineer (2023-2024) in Wipro</li>
            <li>4 year as a Android Developer (2020-2024) in TCS</li>
            <li>2 year as a Interior Designer (2022-2024)</li>
           </ul></div>
           </div>
           <div className="languages" >
           <div><h1>Languages:</h1><input className='input-capital' type='text' onChange={getdata} name='languages' value={value.languages}/></div>
           <div className='suggetion'><h1>Suggetion-</h1><ul><li>English</li><li>Hindi</li><li>French</li><li>Urdu</li></ul></div>
           </div>
           <div className="contact">
           <div><h1>Phone No:</h1><input className='input-capital' type='number' onChange={getdata} name='phone' value={value.phone} placeholder='+91XXXXXXXXXX'/></div>
           <div><h1>Gmail Acc:</h1><input  className='input-small' type='gmail' onChange={getdata} name='gmail' value={value.gmail} placeholder='XYZ@gmail.com'/></div>
           <div><h1>Additional Acc:</h1><input className='input-capital' type='text' onChange={getdata} name='additionalaccount' value={value.additionalaccount} placeholder='Linked-In, Instagram, Gighub, Facebook'/></div>
           </div>
           <div className="projects" >
           <div><h1>Projects:</h1><input className='input-capital' type='text' onChange={getdata} name='projects' value={value.projects}/></div>
           <button className={`submit-m ${formcount==6? 'active-submit-m':''}`}onClick={getresume}>submit</button>
           <div className='suggetion'><h1>Suggetion-</h1>
           <ul><li>Ai Tool</li>
           <li>Weather App</li>
           <li>Video Call App</li>
           <li>E-Commerce Website</li>
           </ul></div>
           </div>
           </div>
           <div  className={`submit ${formcount==6? 'active-submit':''}` } onClick={getresume}>Submit</div>
           <div className={`previous ${formcount>=1? 'active-previous':''}` } onClick={countminus} ><i className="fa-solid fa-arrow-left"></i>Back</div>
             <div className={`next ${formcount==6? 'active-next':''}` } onClick={countplus} >Next <i className="fa-solid fa-arrow-right"></i></div>
        </div>
        </div>
        <div className='result-body'>
        <div className='final-design'></div>
        <div className='main-resume-body' ref={pdfRef}>
          {finalres}
        </div>
        <div className='download-body'>
          <div className='file-name'><h1>File Name</h1><h2>Resume</h2></div>
          <button className='download-btn' onClick={download}>download<i className="fa-solid fa-download"></i></button>
        </div>
        </div>
        <div className='download-pdf' ref={pdfRef}>
        {downloadres}
        </div>
        </div>
        </div>
        </>

    )
}