import React, { useState } from 'react'
import './home.css';
import {sample} from './sample'
import Data from './data';
import homeimg from './homeimg.jpg'
import Footer from './footer';
import logo from './logo.png'
import { Link } from 'react-scroll';
import { BarLoader } from 'react-spinners';
export default function Home() {
  let [hide,sethide] =useState(0);
 let [index,setindex] = useState()
  let move=(i)=>{
    setindex(i)
    sethide(1);
  }  

   let start =  sample.map((value,i)=>{
  return(<>
  <img onClick={()=>move(i)} className='sample-img' src={value.img}/> </>)}) 

  let [load,setload] = useState(true);
  return (
   <>
   <div className={`loader ${load ? '' : 'active-loader'}`}>
    <BarLoader loading={load} color='#0377fc'/></div>

   <div className='header'><img className='header-logo' src={logo} alt='logo' onLoad={()=>setload(false)} /><Link to ={'footer'} smooth={true} offset={100} duration={500}><i id='three-dot' className="fa-solid fa-ellipsis-vertical"></i></Link></div>
    <div className='top-body'>
    <div className={`main-back2 ${hide==0?'active-main-back2':''}`}> <i id='main-back2-i' className="fa-solid fa-arrow-left"  onClick={()=>sethide(!hide)}></i></div>
  
    <div className='left-top-body'><h1>Crack Your Dream Job By Our Resume</h1>
    <Link to={'center-body'} smooth={true} duration={500} offset={-100}><button>Get Resume</button></Link>
    <p>We are here to deliver a professional resume to get your dream job. Our aim is to enhance your resume quality.</p>
    </div>
    <div className='right-top-body'><img src={homeimg} alt='homeimg'/></div>
    </div>
    <div className='center-body'>{start}</div>
    <Data hide = {hide} index ={index}/>
    <Footer/>
   </>
  )
}
