import './footer.css'

export default function Footer(){
    return(<>
        <div className="footer"> 
        <div className='news-box'>
            
            <h1>Newsletter</h1>
            <p>Please, subscribe to our newsletter to keep updated about our news and offers.</p>
            <div>
            <i className="fa-regular fa-envelope"></i>
                <input type='text' placeholder=' Your email'/>
                <button>Subscribe</button>
            </div>
        </div>
        <div className='office'>
            <h1>Our office</h1>
            <div className='footer-contact'>
            <i className="fa-solid fa-location-dot"></i>
            <p>Mira-Road Thane,(Maharastra)-India</p>
            </div>
            <div className='footer-contact'>
            <i className="fa-regular fa-envelope"></i>
            <p>azamk0956@gmail.com</p>
            </div>
        </div>
        <div className='payment'>
            <h1>Payments accepted</h1>
            <div className='payment-method'>
                <div><i className="fa-brands fa-cc-visa"></i></div>
                <div><i className="fa-brands fa-cc-mastercard"></i></div>
                <div><i className="fa-brands fa-cc-paypal"></i></div>
            </div>
        </div>
        </div>
        <div className='azimbashar'>Made By:<p>Mr Azim Bashar</p></div>
        </>
    )
}