import './App.css';
import Home from './home'

function App() {
  return (
    <div className="App">
    <Home/>
    </div>
  );
}

export default App;
