export let sample =[
    {img : "res2.jpg"},
    {img : 'res10.jpg'},
    {img : 'res3.jpg'},
    {img : "res4.jpg"},
    {img : 'res5.jpg'},
    {img : 'res1.jpg'},
    {img : "res7.jpg"},
    {img : 'res8.jpg'},
    {img : 'res9.jpg'},
    {img : "res6.jpg"},
    {img : 'res11.jpg'},
    {img : 'res12.jpg'},
    {img : "res13.jpg"},
    {img : 'res14.jpg'},
    {img : 'res15.jpg'},
    {img : "res16.jpg"},
    {img : 'res17.jpg'},
    {img : 'res18.jpg'}
]