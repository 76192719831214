import React from 'react'
import './resume18.css'
import './common.css'
export default function Resume18({rdata}) {
  return (
    <div className='main-resume'>
    <div className='resume18'>
    <div className='r18-d-name'><h1>{rdata.name}</h1><h2>{rdata.work}</h2></div>
    <div className='r18-main-con'>
    <div className='r18-div'>
        <h1 className='r18-d-h1'>Skills</h1>
        <p className='r18-d-p'>{rdata.skills}</p>
        </div>
        <div className='r18-div'>
        <h1 className='r18-d-h1'>Contact</h1>
        <p className='r18-d-p'><i className="fa-solid fa-square-phone"></i>{rdata.phone}</p>
        <p className='r18-d-p'><i className="fa-solid fa-envelope"></i>{rdata.gmail}</p>
        <p className='r18-d-p'><i className="fa-solid fa-circle-user"></i>{rdata.additionalaccount}</p>
        </div>
        <div className='r18-div'>
        <h1 className='r18-d-h1'>Languages</h1>
        <p className='r18-d-p'>{rdata.languages}</p>
        </div>
        <div className='r18-div'>
        <h1 className='r18-d-h1'>Educations</h1>
        <p className='r18-d-p'>{rdata.institute}</p>
        <p className='r18-d-p'>{rdata.course}</p>
        <p className='r18-d-p'>{rdata.additionalcourse}</p>
        </div>
        <div className='r18-div'>
        <h1 className='r18-d-h1'>Experience</h1>
        <p className='r18-d-p'>{rdata.experience}</p>
        </div>
        <div className='r18-div'>
        <h1 className='r18-d-h1'>Projects</h1>
        <p className='r18-d-p'>{rdata.projects}</p>
 </div>
    </div>
    </div>
    </div>
  )
}